@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Scribble&display=swap');

:root {
  --primary-color: #141B22;
  --secondary-color: #FD3D0C;
  --btn-bg1: #2C1E20;
  --btn-bg2: #FFEBE6;
  --font-1: "poppins", sans-serif;
  
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 10px;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Poppins', sans-serif;
  background-color: #f5f5f5;
}

