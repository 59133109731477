.Navbar_section {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.2);
    padding: 0 2rem;
    background: linear-gradient(to right, var(--primary-color) 50%, var(--secondary-color) 20%);
}
.nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    max-width: 1440px;
    margin: auto;
    padding: 1rem;
}

.Navbar_section .nav__logo {
    font-size: 2.0rem;
    font-weight: 800;
    text-transform: capitalize;
    font-family: "poppins",sans-serif;
    color: white;
    font-weight: 900;
    text-decoration: none;
}
.nav__logo span {
    color: var(--secondary-color);
}

.nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__list {
    display: flex;
    column-gap: 2rem;
    list-style: none;
}

.nav__link {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    font-size: 1.25rem;
    color: white;
    font-weight: 2rem;
    transition: .3s;
    font-weight: 600;
    text-decoration: none;
}
.nav__link::after{
    content: "";
    position: relative;
    display: block;
    width: 0;
    height: 3px;
    background-color: black;
    transition: width.3s ease-in-out;
}

.nav__link:hover::after{
    width: 100%;
}

.nav__icon,
.nav__close,
.nav__toggle {
    display: none;
}


/* cative link*/

.active-link,
nav__link:hover {
    color: white;
}


/*==========BREAK POINT ========*/
/* For medium devices */

@media screen and (max-width: 768px) {
    .Navbar_section  {
        background: var(--primary-color);
        top: 0;
    }

    .nav {
        height: var(--header-height);
        background-color: var(--primary-color);
    }

    .nav__menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--secondary-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }

    /* Show menu */

    .show-menu {
        bottom: 0;
        background-color: var(--secondary-color);
    }

    .nav__list {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        justify-content: center;
        gap: 1rem;
        z-index: 3;
    }


    .nav__icon {
        font-size: 2.2rem;
        color: white;
    }

    .nav__close {
        position: absolute;
        right: 1.3rem;
        bottom: .5rem;
        font-size: 3rem;
        cursor: pointer;
        color: var(--title-color);
    }

    .nav__close:hover {
        color: var(--title-color-dark);
    }


    .nav__toggle {
        font-size: 1.1rem;
        cursor: pointer;
    }
    .nav__logo {
        padding-top: 0;
    }

    .nav__icon,
    .nav__close,
    .nav__toggle {
        display: block;
        color: white;
    }
}

/* For small devices */
@media screen and (max-width: 350px){
    .Navbar_section{
        width: 100%;
    }
    .nav {
        width: 100%;
    }
    .nav__menu {

        width: 100%;
    }
    .nav__list {
        column-gap: 0;
    }
    .nav .nav__logo {
        font-size: 1.5rem;
    }
}