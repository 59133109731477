.Home_container_section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    text-align: center;
    background:  linear-gradient(to right, var(--primary-color) 50%, var(--secondary-color) 0%);
    width: 100%;
}
.Home_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    background-color: #f5f5f5;
    padding: 2.9rem 5rem;
    background:  linear-gradient(to right, var(--primary-color) 50%, var(--secondary-color) 0%);
    gap: 1rem;
    width: 1440px;
}
.Home_Content {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 0.2rem;
    animation: moveInLeft 1s linear;
    padding: 1rem;
}
.Home_Content h1 {
    background-color: #252c33;
    width: max-content;
    height: max-content;
    padding: 0.6rem 0.2rem;
    border-radius: 5px;
    font-size: 1.1rem;
    text-align: left;
    font-weight: 700;
    color: white;
    padding:1rem;
    margin: 1rem 0;
    position: relative;
    z-index: 0;
}
.Home_Content h1::after {
    content: "";
    display: block;
    width: 45%;
    top: 10%;
    left: 0;
    height: 80%;
    position: absolute;
    background-color: #FD3D0C;
    border-radius: 5px;
    z-index: -5;
    animation: RightToLeft 3s linear infinite;
} 

@keyframes RightToLeft {
    0% {
        left: 0;
    }
    50% {
        left: 54%;
    }
    100% {
        left: 0;
    }
}

.Home_Content h2 {
    font-size: 5rem;
    text-align: left;
    line-height: 4.6rem;
    font-weight: 700;
    color: white;
    margin: 1rem 0;
}
.Home_Content p {
    font-size: 1.2rem;
    text-align: left;
    font-weight: 400;
    color: grey;
}
.Home_btn {
    background-color: #FD3D0C;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    width: 40%;
    margin-top: 2rem;
    padding: 0.5rem;
    text-decoration: none;
    border-radius: 5px;
    transition: 0.4s;
}
.Home_btn:hover {
    background-color: white;
    color: #FD3D0C;
}

.Home_image {
    position: relative;
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    animation: moveInRight 1s linear;
    z-index: 0;
    padding: 0 1rem;
    margin-top: 5.5rem;
}

.Home_image img {
    width: 600px;
    height: 600px;
    object-fit: cover;
    z-index: 0;
}
.Calorie {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #141B22;
    color: #FD3D0C;
    left: 1rem;
    align-items: center;
    width: max-content;
    height: max-content;
    padding: 0.8rem;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #FD3D0C;
    animation: moveInOut 1s linear infinite;
}
.Calorie p {
    font-size: 1rem;
    text-align: left;
    font-weight: 400;
    color: white;
}
.HeartRate {
    display: flex;
    width: max-content;
    height: max-content;
    align-items: center;
    position: absolute;
    flex-direction: column;
    background-color: #141B22;
    color: #FD3D0C;
    padding: 0.8rem;
    right: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #FD3D0C;
    animation: moveInOut 1s linear infinite;
}

.HeartRate p {
    font-size: 1rem;
    text-align: left;
    font-weight: 400;
    color: white;
}


@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }
    80% {
        transform: translateX(1rem);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }
    80% {
        transform: translateX(-1rem);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInOut {
    0% {
        margin-top: 15px;
    }
    50% {
        margin-top: 0px;
    }
    100% {
        margin-top: 15px;
    }
}

@media screen and (max-width: 1025px) {
    .Home_container {
        width: 100%;
    }
    .Home_Content h1 {
        font-size: 1rem;
    }
    .Home_Content h2 {
        font-size: 2.4rem;
        line-height: normal;
    }
    .Home_Content p {
        font-size: 1rem;
    }
    .Home_btn {
        width: 200px;
    }
    .Home_image img {
        width: 500px;
        height: 400px;
    }
}

@media screen and (max-width:769px) {
    .Home_container {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        height: 100%;
    }
    .Home_Content {
        width: 1fr;
        padding: 2rem;
    }
    .Home_image {
        width: 100%;
        height: 100%;
    }
    .Home_image img {
        width: 500px;
        height: 400px
    }
}
@media screen and (max-width:426px) {
    .Home_container {
        gap: 0.5rem;
        height: 100%;
        width:100%;
        padding: 2rem 0;
    }
    .Home_Content {
        width: 1fr;
        padding: 2rem;
    }
    .Home_image {
        width: 100%;
        height: 100%;
    }
    .Home_image img {
        width: 100%;
        height: 100%;
    } 
}
@media screen and (max-width:376px) {
    .Home_container {
        gap: 0.5rem;
        height: 100%;
        justify-content: center;
        width:100%;
        padding: 2rem 0;
    }
    .Home_Content {
        width: 1fr;
        padding: 2rem;
    }
    .Home_Content h1 {
        font-size: 1rem;
    }
    .Home_Content h2 {
        font-size: 1.8rem;
    }
    .Home_Content p {
        font-size:  1rem;
    }
    .Home_btn {
        font-size: 1rem;
    }
    .Home_image {
        width: 100%;
        height: 100%;
        margin: 0;
    }
    .Home_image img {
        width: 100%;
        height: 100%;
    }
    .Calorie {
        font-size: 0.8rem;
        bottom: 1rem;
    }
    .Calorie p {
        font-size: 0.8rem;
    }
    .HeartRate {
        font-size: 0.8rem;
      top: 1rem;
    }
    .HeartRate p {
        font-size: 0.8rem;
    }
}