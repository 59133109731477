.Class_section_container {
    background-color: var(--primary-color);
    height: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
}
.Class_section {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    text-align: center;
    max-width: 1440px;
    padding: 0 6rem;
}
.Class_Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;

}
.Class_Content h2 {
    color: var(--secondary-color);
    width: 300px;
    padding: 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: var(--font-1);
    background-color: #2C1E20;
    border-radius: 5px;
}

.Class_Content h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: white;
}


/*Class Name Cards*/
.Class_names {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.Class_name_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: white;
    width: 300px;
    border-radius: 15px;
    transition: all 0.8s ease-in;
    cursor:pointer;
}
.Class_name_card:hover {
    background: var(--btn-bg2);
}
.Class_name_card img {
    width: 100%;
    height: 80%;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.Class_detail {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    background-color:transparent;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.Class_detail p {
    text-align: left;
    padding: 0 1rem;
    color: grey;
    font-size: 0.7rem;
}
.Title {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Class_detail h3 {
    font-size: 1rem;
    font-family: var(--font-1);
    color: var(--primary-color);
    border-left: 2px solid grey;
    padding: 0.3rem 1rem;
    transition: 0.4s;
}
.Class_Content h3:hover {
    color: var(--secondary-color);
    cursor: pointer;
}
.Title_logo {
    width: 50px;
    height: 50px;
    padding: 0.2rem;
    color: var(--secondary-color);
    font-size: 1rem;
    margin: 0.5rem;
}
.Line {
    height: 6px;
    border-radius: 5px;
    width: 90%;
    background-color: var(--btn-bg2);
    position: relative;
    margin: 0.8rem 1rem;
}

.Line::before {
    content: "";
    height: 6px;
    width: 90%;
    background-color: var(--secondary-color);
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
}
.Class_Percent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.8rem;
}
.Class_Percent strong {
    font-size: 0.7rem;
}

@media screen and (max-width: 1025px) {
    .Class_section {
        padding: 0;
    }
    .Class_name_card {
        width: 300px;
    }
    .Class_names {
        gap: 0.1;
    }
}

@media screen and (max-width: 769px) {
    .Class_section {
        padding: 0;
    }
    .Class_name_card {
        width: 300px;
    }
    .Class_names {
        gap: 0.1;
    }
    .Class_Content h1 {
        font-size: 2rem;
    }
    .Class_Content h2 {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 426px) {
    .Class_section {
        padding: 0;
    }
    .Class_name_card {
        width: 100%;
    }
    .Class_names {
        gap: 0.1;
    }
    .Class_Content h1 {
        font-size: 1.5rem;
    }
    .Class_Content h2 {
        font-size: 1rem;
    }
}