.Trainer_Section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 8rem;
  text-align: center;
  gap: 2rem;
  background: var(--primary-color);
  position: relative;
}

.Trainer_Card {
  border: 2px solid ;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
}

.Trainer_Card h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  font-family: "poppins", sans-serif;
  line-height: 2px;
}
.Trainer_Card h2 {
  font-size: 1rem;
  font-weight: 300;
  color:gray;
  font-family: "poppins", sans-serif;
  line-height: 2px;
}

.Trainer_Card img {
  display: flex;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 9px solid var(--btn-bg1);
  justify-content: center;
  padding: 1rem;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s ease-in;
  filter:grayscale(100%)
}

.Trainer_Card img:hover {
  border: 9px solid var(--btn-bg2);
  filter:grayscale(0%);
}
.Image {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  width: auto;
}

.Social_medias {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.Social_medias a {
  display: flex;
  font-size: 1rem;
  width: auto;
  height: auto;
  font-weight: 600;
  color: var(--secondary-color);
  font-family: "poppins", sans-serif;
  line-height: 0;
  border: 1px solid red;
  border-radius: 50%;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in;
}


.Trainer_Card img:hover {
  border: 9px solid var(--secondary-color);
}

.Trainer_Card .Trainer_Title h1:hover {
  color: var(--secondary-color);
  cursor: pointer;
}

.Social_medias a:hover {
  color: white;
  background-color: var(--secondary-color);
}
.Trainer_Title h2 {
  font-size: 1rem;
  color: white;
  font-weight: 400;
  background-color: var(--btn-bg1);
  width: max-content;
  padding: 1rem;
  border-radius: 10px;
  color: var(--secondary-color);
  margin-top: 5rem;
}
.Trainer_Title {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "poppins", sans-serif;
}

.Trainer_Title h1 {
  font-size: 2.5rem;
  color: white;
  font-weight: 700;
  margin: 2rem 0;
}

/***Satsfaction section***/
.Satsfaction_Section {
  display: flex;
  width: 100%;
  position: absolute;
  transform: translateY(-50%);
  top: 0;
  left: 0;
  text-align: center;
  justify-content: center;
}
.Satsfation {
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: max-content;
  right: auto;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 4rem;
  background-color: var(--secondary-color);
  padding: 2rem 3rem;
  border-radius: 15px;
}
.Satsfation .Satsfation_Content {
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  color: white;
  width: 100%;
}
.Satsfation .Satsfation_Content h1 {
  font-size: 3rem;
  font-weight: 700;
  transform: rotate(-90deg);
}
.Sats_Emoji {
  font-size: 4rem;
  font-weight: 700;
}
.Sats_detail {
  text-align: left;
}
@media screen and (max-width: 1025px) {
  .Trainer_Section {
    padding: 1rem 2rem;
  }
  .Satsfaction_Section{
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    justify-content: center;
    position: unset;
    padding: 2rem 5rem;
    transform: unset;
  }
  .Satsfation {
    grid-template-columns: auto auto;
    width: 100%;
    justify-content: center;
    position: unset;
    padding: 2rem 5rem;
    transform: unset;
  }
}
@media screen and (max-width: 769px) {
  .Trainer_Section {
    padding: 3rem 2rem;
  }
  .Trainer_Card img {
  }
  .Trainer_Card h1 {
    font-size: 1.2rem;
  }
  .Trainer_Card h2 {
    font-size: 0.6rem;
  }
  .Trainer_Title h1 {
    font-size: 2rem;
  }
  .Trainer_Title h2 {
    font-size: 1.2rem;
  }
  .Satsfaction_Section {
    grid-template-columns: auto auto;
    width: 100%;
    justify-content: center;
    position: unset;
    padding: 1rem;
    transform: unset;
  }
 
}

@media screen and (max-width: 428px){
  .Satsfation {
    grid-template-columns: auto;
    width: 100%;
    justify-content: center;
    position: unset;
    transform: unset;
    row-gap: 1rem;
  }
  .Satsfation .Satsfation_Content h1 {
    font-size: 1.5rem;
  }
  .Satsfation .Satsfation_Content .Sats_Emoji {
    font-size: 2rem;
  }
}

