.Mobile_app_Section {
    display: flex;
    justify-content: center;
    background-color: var(--primary-color);
}
.Mobile_app {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    
    color: white;
    gap: 5rem;
    padding: 2rem;
    max-width: 1440px;
}
.mobile_image {
    width: 30%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
}
.app_img {
    width: 300px;
    padding-top: 2rem;
}
.circle_one {
    width: 400px;
    height: 400px;
    background: linear-gradient(to right, transparent, var(--secondary-color));
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    right: 0;
    z-index: -1;
    animation: rotate_animation 18s  linear infinite;
}
.circle_one::after {
    content: "";
    width: 90%;
    height: 90%;
    background: var(--primary-color);
    border-radius: 50%;
    position: absolute;
    right: 5%;
    top: 5%;
    z-index: -1;
}

@keyframes rotate_animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}






.Download_theApp {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.Download_theApp h2 {
    color: var(--secondary-color);
    background-color: var(--btn-bg1);
    width: max-content;
    font-size: 1rem;
    font-weight: 400;
    padding: 1rem;
    border-radius: 15px;
}

.Download_theApp h1 {
    font-size: 2.5rem;
    line-height: normal;
}

.Download_theApp p {
    font-size: 0.8rem;
    color: grey;
    padding: 1rem 0;
}

.download_btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    justify-content: left;
}

.download_btn button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: max-content;
    border: none;
    border-radius: 15px;
    padding: 0;
    width: 200px;
}
.download_btn .Apple {
    background-color: var(--secondary-color);
    color: white;
    font-size: 2rem;
    text-align: left;
}
.download_btn .PlayStore  {
    color: var(--secondary-color);
    background-color:transparent;
    font-size: 2rem;
    border: 2px solid var(--secondary-color);
    text-align: left;

}
.download_btn p {
    color: white;
    padding: 1rem;
}
.download_btn p span {
    font-size: 1rem;
    font-weight: 800;
}

.download_btn .PlayStore p span:last-of-type {
    font-size: 1rem;
    color: var(--secondary-color);
    text-transform: capitalize;
    font-weight: 800;
}

@media screen and (max-width: 1025px) {
    .Download_theApp {
        width: 100%;
    }
   .Download_theApp h2 {
        width:fit-content
    }
    .mobile_image {
        width: 50%;
    }
    .circle_one {
        width: 300px;
        height: 300px;
    }
}

@media screen and (max-width: 769px){
    .Download_theApp {
        width: 100%;
    }
    .Download_theApp h1 {
        font-size: 1.5rem;
    }
    .Download_theApp h2 {
        font-size: 1rem;
        width: fit-content;
    }
    .download_btn p span {
        font-size: 1rem;
    }
    .download_btn .PlayStore p span:last-of-type {
        font-size: 1rem;
    }
    .download_btn p {
        padding: 0.5rem;
    }
    .download_btn button {
        width: auto;
    }
}
@media screen and (max-width: 500px) {
    .Mobile_app {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        gap: 1rem;
    }
    .circle_one{
        display: none;
    }
    .Download_theApp {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .download_btn {
        width: 100%;
    }
}


